<template>
    <div class="Sessions">
        <div class="Sessions__top-bg page-top-bg"></div>
        <div class="Sessions__content page-content content shadow1">
            <h1>Un accompagnement de qualité, bienveillant et adapté à chaque individualité, à chaque problématique</h1>
            <div class="bg-gray-200 m-top-32">
                <div class="">
                    <h2 class="underline">Ma démarche </h2>
                    <br />
                    <p>
                        Je vous apporte, en toute <span class="font-semibold">confidentialité</span>, une
                        <span class="font-semibold">écoute active, bienveillante et sans jugement</span>,
                        un soutien, des <span class="font-semibold">outils</span> pour opérer les changements qui vont
                        vous permettre d'aller de l’avant.
                        <br />
                        <br />
                        J’accompagne <span class="font-semibold">des adolescents et des adultes de tout âge</span>.
                        <br />
                        Je vous accueille en <span class="font-semibold">consultation individuelle</span> à mon domicile
                        sur Bordeaux Chartrons.
                        J’interviens également au sein des organisations publiques et privées où je propose des
                        séances de <span class="font-semibold">relaxation en groupe</span> ou des
                        <span class="font-semibold">séances individuelles</span>.
                        <br />
                        Enfin je vous invite à découvrir la Sophrologie et vous accorder un temps pour vous lors de
                        séances de relaxation en groupe que je propose au sein d’espaces de bien-être. A découvrir sur
                        la page Facebook:
                        <a href="https://www.facebook.com/BordeauxBienEtre/?__tn__=%2Cd%2CP-R&eid=ARCPasMTlpqk7wkyOx5VovurTVtF8VXPtnM6lAaLgvOTA8CVmoYRnTAGEODwQpf6f2-Fqt0G7tf5TEGG" target="_blank" class="blue-link">France Ehny Sophrologue</a>
                    </p>
                </div>
            </div>
            <br />
            <br />
            <div>
                <h2 class="underline">Déroulement d’une séance</h2>
                <br />
                <div style="max-width: 800px;">
                    <h3>• Accueil</h3>
                    La durée d’une séance est de 45 minutes à 1 heure. En début de séance je vous propose un
                    temps d’échanges, plus long lors du premier rendez-vous, afin de faire connaissance.
                    <br />
                    <br />
                    <h3>• Pratique</h3>
                    La méthode s’appuie sur des exercices de respiration, des mouvements simples et des visualisations
                    positives pour favoriser le lâcher-prise.Vous êtes les yeux fermés, en position assise ou
                    debout et je vous accompagne par la voix. Il n’y a aucun contact physique. Je pratique la relaxation
                    en même temps que vous, les yeux fermés.
                    <br />
                    <br />
                    <h3>• Ressenti</h3>
                    La séance se termine par un temps de description de ce que vous avez ressenti, ce que vous
                    avez vécu pendant la relaxation, à l’oral, à l’écrit ou par le dessin.
                    <br />
                    <br />
                    <h3>• Autonomie</h3>
                    La séance peut être enregistrée pour que vous puissiez la réaliser ensuite en autonomie chez
                    vous.
                    <br />
                    <br />
                </div>
                <div>
                    <br />
                    <h3 class="text-green text-center italic">Chaque séance est une nouvelle proposition à vivre, sans à priori,
                        sans jugement.</h3>
                    <br />
                    <br />
                    Une seule séance vous permettra de découvrir la pratique et de vous détendre, mais si vous
                    consultez pour une problématique particulière ou dans un objectif de développement personnel,
                    <span class="font-semibold">il est préférable de pratiquer régulièrement pour obtenir des résultats.</span>
                    <br />
                    <br />
                    <span class="font-semibold"><u>Important:</u> La pratique de la sophrologie peut être complémentaire à
                        une thérapie médicale mais ne saurait s’y substituer.</span>
                </div>
            </div>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
        </div>
    </div>
</template>

<style type="text/scss" scoped>
    @import "./_Sessions.scss";
</style>

<script>
    export default {
        props: {
        },
      metaInfo: {
        title: 'Séances',
        titleTemplate: `%s - Sophrologie Bordeaux`,
        meta: [
          {'http-equiv': 'Content-Type', content: 'text/html; charset=utf-8'},
          {name: 'viewport', content: 'width=device-width, initial-scale=1'},
          {name: 'description', content: "Sophrologue à Bordeaux. La sophrologie est un outil fabuleux qui permet d’être pilote de sa vie, d’optimiser ses capacités et de s’adapter au..."}
        ]
      },
    };
</script>
