<template>
    <div class="SophrologyForBusiness">
        <div class="SophrologyForBusiness__top-bg page-top-bg"></div>
        <div class="SophrologyForBusiness__content page-content content shadow1">
            <h1>Et si la santé de votre entreprise passait par le bien-être de vos collaborateurs? </h1>
            <div class="flex bg-gray-200 m-top-32">
                <div class="flex-grow">
                    <h2 class="underline">L’efficacité préventive de la Sophrologie</h2>
                    <br />
                    <p>
                        <span class="font-semibold">Dans le cadre de la prévention des risques psycho-sociaux</span>,
                        la sophrologie contribue à développer la <span class="font-semibold">Qualité de Vie au Travail,
                        améliore les capacités d’adaptation, le relationnel, et permet de prévenir les conflits et le
                        turnover.</span>
                    </p>
                </div>
                <div class="business-image" style="margin-left: 32px;">
                    <img src="../../assets/profil_2.jpg" class="m-top-64" style="width: 200px;" alt="profil_picture"/>
                </div>
            </div>
            <br />
            <br />
            <div>
                <h2 class="underline">Concrètement, de quoi s’agit-il?</h2>
                <br />
                <div>
                    Il s’agit concrètement de stimuler les ressources internes de la personne:
                    <br />
                    <div class="m-left-16">
                        <div class="m-top-16">• confiance</div>
                        <div class="m-top-4">• créativité</div>
                        <div class="m-top-4">• attention et concentration</div>
                        <div class="m-top-4">• consolidation des capacités</div>
                    </div>
                    <br />
                    J’interviens au sein de votre établissement où je propose des séances de
                    <span class="font-semibold">relaxation en groupe</span>  (de 3 à 12 personnes) ou des
                    <span class="font-semibold">séances individuelles.</span>
                    <br />
                    <br />
                    Une séance de relaxation dure de 45 minutes à 1 heure (voir page « séances »). Elle peut être
                    programmée au moment de la pause déjeuner ou en fin de journée, et représenter pour chacun
                    un moment pour soi, un retour en soi ou une simple parenthèse de détente et de bien-être.
                </div>
            </div>
            <br />
            <br />
            <div>
                <h2 class="underline">Mon point fort : la polyvalence</h2>
                <br />
                <div>
                    <table class="w-full m-top-16">
                        <tr>
                            <td class="text-center font-semibold text-green" style="font-size: 20px">Sophrologue</td>
                            <td class="text-center font-semibold text-green" style="font-size: 20px">Scientifique</td>
                            <td class="text-center font-semibold text-green" style="font-size: 20px">Pédagogue</td>
                        </tr>
                    </table>
                    <br />
                    <br />
                    Je viens du monde de l’entreprise, et mon plus grand plaisir est de <span class="font-semibold">
                    transmettre à mes pairs</span> ces outils et connaissances qui nous aident à
                    <span class="font-semibold">améliorer notre qualité de présence, notre qualité de vie, notre
                        efficacité, notre bien-être au travail.</span>
                    <br />
                    <br />
                    Je suis bien sûr à votre disposition pour échanger et
                    <span class="font-semibold">construire une programmation adaptée
                    de séances</span> en fonction de vos besoins et de votre projet. Le sur-mesure, c’est un exercice de
                    créativité qui me convient bien, alors n’hésitez pas à <router-link to="/contact-et-tarifs" class="blue-link">me contacter</router-link>.
                </div>
            </div>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
        </div>
    </div>
</template>

<style type="text/scss" scoped>
    @import "./_SophrologyForBusiness.scss";
</style>

<script>
    export default {
      metaInfo: {
        title: 'En entreprise',
        titleTemplate: `%s - Sophrologie Bordeaux`,
        meta: [
          {'http-equiv': 'Content-Type', content: 'text/html; charset=utf-8'},
          {name: 'viewport', content: 'width=device-width, initial-scale=1'},
          {name: 'description', content: "Sophrologue à Bordeaux. La sophrologie est un outil fabuleux qui permet d’être pilote de sa vie, d’optimiser ses capacités et de s’adapter au..."}
        ]
      },
        props: {
        },
    };
</script>
