<template>
    <div class="WhatIsSophrology">
        <div class="WhatIsSophrology__top-bg page-top-bg"></div>
        <div class="WhatIsSophrology__content page-content content shadow1 ">
            <h1>Et si votre bien-être passait par  la sophrologie existentielle ?</h1>
            <div class="flex bg-gray-200 m-top-32">
                <div class="flex-grow">
                    <h2 class="underline">L'origine de la sophrologie</h2>
                    <br />
                    <p>
                        La Sophrologie (terme ayant pour racines SOS : harmonie – PHREN : conscience – LOGOS : étude) a
                        été créée en 1960 par le neuropsychiatre Alfonso Caycedo, dans le but de développer une
                        « <span class="font-semibold">science de la conscience en harmonie</span> ».
                        <br />
                        Il s’est inspiré à la fois de certaines techniques de relaxation, de techniques orientales
                        (yoga, bouddhisme et zen) adaptées aux besoins et capacités de notre civilisation occidentale
                        mais également à l’évolution des sciences en neurologie et en biologie.
                    </p>
                </div>
                <div class="origin-sophro-image " style="margin-left: 32px;">
                    <img src="../../assets/utcons.jpg" class="m-top-64" style="width: 200px;" alt="utcons"/>
                </div>
            </div>
            <br />
            <br />
            <h2 class="underline">Un champ d'application vaste</h2>
            <br />
            <div>
                « <i>Faire de la sophrologie</i> » c’est pratiquer la relaxation dynamique pour…
                <br />
                <div class="m-left-16">
                    <div class="m-top-16">• Accueillir et  dépasser un évènement de la vie qui génère de l’anxiété</div>
                    <div class="m-top-4">• Gérer stress et émotions pour développer une meilleure adaptabilité aux
                        changements anxiogènes</div>
                    <div class="m-top-4">• Découvrir et développer ses ressources intérieures pour optimiser son
                        potentiel</div>
                    <div class="m-top-4">• Renforcer la confiance en soi, la motivation, l’affirmation de soi</div>
                    <div class="m-top-4">• Optimiser la préparation d'un évènement spécifique (examen, compétition,
                        entretien, prise de parole en public)</div>
                    <div class="m-top-4">• Améliorer la relation à soi et aux autres!</div>
                </div>
            </div>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
        </div>
    </div>
</template>

<style type="text/scss" scoped>
    @import "./_WhatIsSophrology.scss";
</style>

<script>
    export default {
      metaInfo: {
        title: 'Qu\'est ce que la sophrologie ?',
        titleTemplate: `%s - Sophrologie Bordeaux`,
        meta: [
          {'http-equiv': 'Content-Type', content: 'text/html; charset=utf-8'},
          {name: 'viewport', content: 'width=device-width, initial-scale=1'},
          {name: 'description', content: "Sophrologue à Bordeaux. La sophrologie est un outil fabuleux qui permet d’être pilote de sa vie, d’optimiser ses capacités et de s’adapter au..."}
        ]
      },
        props: {
        },
    };
</script>
