<template>
    <div class="WhoAmI">
        <div class="top-bg page-top-bg"></div>
        <div class="WhoAmI__content page-content content shadow1">
            <h2 class="underline">Comment je suis venue à la sophrologie</h2>
            <br />
            <p>
                Scientifique de formation, j’ai acquis une expérience très riche dans le secteur privé en tant
                qu’ingénieur d’études en informatique où j’ai eu l’opportunité de conduire des projets et manager des
                équipes, puis dans la fonction publique en tant qu’enseignante.
                <br /><br />
                Tout au long de mon parcours professionnel, <span class="font-semibold">j’ai pris conscience de l’impact
                du stress sur la qualité de vie au travail et au quotidien, sur l’attention, la concentration, la
                gestion des émotions et le relationnel.</span>
                <br /><br />
                Ainsi, dans une démarche de développement personnel et avec un intérêt croissant pour l’être humain
                dans sa globalité: physique-mental-émotionnel-spirituel, j’ai recherché des outils et des méthodes
                permettant d’accéder à une <span class="font-semibold">meilleure connaissance de soi</span> et à un
                <span class="font-semibold">mieux-être</span>. C’est ainsi que j’ai découvert la sophrologie. Ce fut une
                révélation!  J’ai eu rapidement l’envie de me former sur la pratique afin de partager cet outil formidable.
            </p>
            <br />
            <br />
            <div class="flex bg-gray-200">
                <div class="flex-grow">
                    <h2 class="underline">Ma formation de sophrologue</h2>
                    <br />
                    <p>
                        Je suis devenue <span class="font-semibold">sophrologue praticienne certifiée (Rncp)
                        adhérente à la SFS, Société Française de Sophrologie, après 2 années de formation à l’Ecole
                        ISEBA de Bordeaux </span> au cours desquelles la sophrologie est
                        <span class="font-semibold">devenue ma passion.</span>
                        <br /><br />
                        Je poursuis ma formation avec une spécialisation  sur des domaines spécifiques:
                        <span class="font-semibold">entreprise et risques psycho-sociaux,  parentalité, sport, sommeil,
                            alimentation, vieillissement, douleur et soins de support.</span>
                        <br /><br />
                        Je complète ma palette d’outils d’accompagnement avec une formation en
                        <span class="font-semibold">Ennéagramme</span>, très complémentaire à la sophrologie,
                        particulièrement dans le domaine du coaching.
                        <br /><br />
                        Aujourd’hui je partage avec joie ma pratique et mes connaissances pour un accompagnement de
                        qualité, bienveillant et adapté à chaque individualité, à chaque problématique.
                    </p>
                </div>
                <div class="formation-image flex-none" style="margin-left: 32px;">
                    <img src="../../assets/ecole_sophro_bdx.png" style="width: 200px;" alt="ecole_sophro_bdx"/>
                    <br />
                    <br />
                    <img src="../../assets/sfs_logo.png" style="width: 200px;" alt="Sophrologie"/>
                </div>
            </div>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
        </div>
    </div>
</template>

<style type="text/scss" scoped>
    @import "./_WhoAmI.scss";
</style>

<script>
    export default {
      metaInfo: {
        title: 'Qui suis-je',
        titleTemplate: `%s - Sophrologie Bordeaux`,
        meta: [
          {'http-equiv': 'Content-Type', content: 'text/html; charset=utf-8'},
          {name: 'viewport', content: 'width=device-width, initial-scale=1'},
          {name: 'description', content: "Sophrologue à Bordeaux. La sophrologie est un outil fabuleux qui permet d’être pilote de sa vie, d’optimiser ses capacités et de s’adapter au..."}
        ]
      },
        props: {
        },
    };
</script>
