<template>
    <div class="Home ">
        <div class="top-bg page-top-bg"></div>
        <div class="page-content content shadow1">
            <div class="Home__content__content  bg-gray-200">
                <div class="content--profil-image " style="margin-left: 32px;">
<!--                    <img src="../../assets/profil_picture.jpg" class="Home__profil_image"/>-->
                    <img src="../../assets/profil_3.jpg" class="Home__profil_image" alt="Sophrologue France Ehny"/>
                </div>
                <div class="content--text " style="">
                    <h1>France Ehny</h1>
                    <h2>Sophrologue spécialisée</h2>
                    <h2>Sophrothérapeute</h2>
                    <h2>Psychopraticienne en sophrologie existentielle</h2>
<!--                    <div class="resp-container">-->
<!--                        <iframe src="https://player.vimeo.com/video/442656287" class="resp-iframe" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>-->
<!--                    </div>-->
<!--                    <br />-->
                    <p>La sophrologie est un outil fabuleux qui permet d’être pilote de sa vie, d’optimiser ses capacités et de s’adapter au mieux aux situations que l’on rencontre.</p>
                    <p>Complètement convaincue de ses bienfaits, je suis heureuse de partager ce formidable outil d’épanouissement et de bien-être qui vous permettra de trouver un équilibre à la fois mental, émotionnel et corporel.</p>
                    <br />
                    <p>Vous trouverez ici des informations sur <a class="font-semibold underline" href="#">mon approche</a>, <a class="font-semibold underline" href="#">ma pratique</a> et <a class="font-semibold underline" href="#">mes coordonnées</a> afin de prendre un rendez-vous. Bonne visite et à très bientôt !</p>
                    <h3 class="underline inline-block m-top-16">Titre professionnel:</h3>
                    <a href="http://www.sophrologuebordeaux.fr/img/RNCP.1b7f0921.jpg" target="_blank">
                        <img src="../../assets/RNCP.jpg" class="m-top-8" style="width: 200px; " alt="RNCP"/>
                    </a>
                </div>
            </div>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
        </div>
    </div>
</template>

<style type="text/scss">
    @import "./_Home.scss";
</style>

<script>
    export default {
      metaInfo: {
        title: 'Accueil',
        titleTemplate: `%s - Sophrologie Bordeaux`,
        meta: [
          {'http-equiv': 'Content-Type', content: 'text/html; charset=utf-8'},
          {name: 'viewport', content: 'width=device-width, initial-scale=1'},
          {name: 'description', content: "Sophrologue à Bordeaux. La sophrologie est un outil fabuleux qui permet d’être pilote de sa vie, d’optimiser ses capacités et de s’adapter au..."}
        ]
      },
        props: {
        },
    };
</script>
